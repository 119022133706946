<template>
	<div>
		<div class="title">
			<div class="flexX flexcenter">
					<div class="item"><span>当前余额:</span><span class="cdanger">{{common.money | tofixed}}</span></div>
				<div class="item"><span>今日订单提交金额:</span><span class="cdanger">{{common.order_add_gold | tofixed}}</span></div>
				<div class="item"><span>今日订单成功金额:</span><span class="cdanger">{{common.order_success_gold | tofixed}}</span></div>
				<div class="item"><span>今日代付提交金额:</span><span class="cdanger">{{common.pay_add_gold | tofixed}}</span></div>
				<div class="item"><span>今日代付成功金额:</span><span class="cdanger">{{common.pay_success_gold | tofixed}}</span></div>
			</div>
			<div class="flexX flexcenter mt20">				
				<div class="item"><span>冻结余额:</span><span class="cdanger">{{common.money_uncertain | tofixed}}</span></div>
				<div class="item"><span>今日订单提交笔数:</span><span class="cdanger">{{common.order_add_count}}</span></div>
				<div class="item"><span>今日订单成功笔数:</span><span class="cdanger">{{common.order_success_count}}</span></div>
				<div class="item"><span>今日代付提交笔数:</span><span class="cdanger">{{common.pay_add_count}}</span></div>
				<div class="item"><span>今日代付成功笔数:</span><span class="cdanger">{{common.pay_success_count}}</span></div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				common: {
					money:0,
					order_add_gold:0,
					order_success_gold:0,
					pay_add_gold:0,
					pay_success_gold:0,
					money_uncertain:0,
					order_add_count:0,
					order_success_count:0,
					pay_add_count:0,
					pay_success_count:0,
				}
			}
		},
		created() {
			this.commonTotal()
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			commonTotal() {
				this.$api.commonTotal({}).then(res => {
					console.log(res)
					if (res.status == 1) {
						this.common = res.data
					}
				})
			}
		}
	}
</script>
<style scoped="scoped" lang="scss">
	@import '@/style/variables.scss';

	.title {
		background-color: $tableBorderColor;
		padding: 20px;

		.item {
			flex: 1;
		}
	}
</style>
